<template>
  <router-view></router-view>

  <div class="footer">
    <div class="footer-content">
      <div class="footer-logo footer-logobox">
        <div class="logoimgbox">
          <img src="./assets/logo.png" alt="" />
        </div>
        <div class="otherbox">
          <p class="footerlogo-title">上海叁昶文化科技有限公司</p>
          <p class="footerlogo-cue">一旦牵手，放不下手！</p>
          <p>备案号：沪ICP备19042413号</p>
          <p>
            <a
              href="https://xyt.xcc.cn/getpcInfo?sn=1588470135092391936&language=CN&certType=8&url=*.sanchang3.com"
              target="_blank"
              style="position: relative; display: inline-block; height: 38px"
            >
              <div
                style="
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                "
              ></div>
              <embed
                src="https://program.xinchacha.com/web/1588470135092391936=*.sanchang3.com.svg"
                width="103"
                height="38"
                type="image/svg+xml"
                pluginspage="//www.adobe.com/svg/viewer/install/"
              />
            </a>
          </p>
        </div>
      </div>
      <div class="footer-logo-center">
        <div class="footer-title">联系我们</div>
        <p>电子邮箱： sanchangwenhua@tom.com</p>
        <p>地址： 上海市杨浦区逸仙路205号军弘谷科创园1509-1514</p>
        <p>联系电话： 021-55897712</p>
      </div>
      <div class="footer-logo footer-logo-erweima">
        <div class="imgbox">
          <img src="./assets/erweima.png" alt="" />
        </div>
        <p class="footerlogo-title2">关注公众号</p>
      </div>
      <div class="footer-logo">
        <div class="footer-title">关于我们</div>
        <p @click="goto(1)">公司介绍</p>
        <p @click="goto(2)">招聘信息</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {};
  },
  methods: {
    getRoute(num) {
      if (this.$route.path == "/") {
        this.$store.dispatch("SETTARGET", num);
        this.$router.push({
          path: "/",
        });
      } else {
        this.$router.push({
          path: "/",
          query: {
            name: num,
          },
        });
      }
    },
    goto(num) {
      if (num == 1) {
        this.$router.push({
          name: "introduct",
        });
      } else {
        this.$router.push({
          name: "hire",
        });
      }
    },
  },
};
</script>

<style>
.footer {
  background-color: #333333;
  height: 300px;
  overflow: hidden;
  color: #fff;
}
.footer-content {
  display: flex;
  justify-content: space-around;
  padding-top: 50px;
}
.footer-content > div {
  /* margin: 0 60px; */
  cursor: pointer;
}
.footer-content > div p {
  margin: 0 0 20px;
  font-size: 12px;
  color: #a9a9a9;
  cursor: pointer;
}
.footer-content > div .footer-title {
  margin: 0 0 30px;
  font-size: 18px;
  text-align: center;
}
.footer-logo {
  width: 300px;
}
.footer-logo img {
  width: 50px;
  height: 50px;
}
.footer-logo-erweima {
  position: relative;
}
.footer-logo-erweima img {
  width: 100%;
  height: 100%;
  margin-left: 0px;
  margin-bottom: 0px;
}
.imgbox {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%);
}
.footer-logo p {
  text-align: center;
}
.footer-logobox {
  position: relative;
}
.footerlogo-title {
  font-size: 20px !important;
  width: 300px;
  color: #fff !important;
  margin-bottom: 10px !important;
}
.footerlogo-title2 {
  position: absolute;
  top: 120px;
  left: 50%;
  transform: translate(-50%);
  font-size: 20px !important;
  color: #fff !important;
}
.logoimgbox {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
}
.otherbox {
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translate(-50%);
}
.footerlogo-cue {
  font-size: 16px !important;
  color: #fff !important;
  margin-bottom: 10px !important;
}

.footer-logo-center p {
  font-size: 16px !important;
}
</style>
