import { createRouter, createWebHistory,createWebHashHistory} from "vue-router";

const routes = [
  {
    path: '/',
    name:'index',
    component: () => import('../views/index/index')
  },
  {
    path: '/business',
    name:'business',
    component: () => import('../views/business/index')
  },
  {
    path: '/catalogue',
    name:'catalogue',
    component: () => import('../views/catalogue/index')
  },
  {
    path: '/info',
    name:'info',
    component: () => import('../views/info/index')
  },
  {
    path: '/introduct',
    name:'introduct',
    component: () => import('../views/introduct/index')
  },
  {
    path: '/hire',
    name:'hire',
    component: () => import('../views/hire/index')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes,
})

export default router;