import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import CONST from './util/consts'
window.CONST = CONST
import store from "./store";

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import './assets/iconfont/iconfont.css'
import "animate.css";

import 'swiper/js/swiper'
import 'swiper/css/swiper.css'

router.beforeEach((to, from, next) => {    
  // chrome
  document.body.scrollTop = 0
  // firefox
  document.documentElement.scrollTop = 0
  // safari
  window.pageYOffset = 0
  next()
})
createApp(App)
  .use(router)
  .use(store)
  .mount('#app')
