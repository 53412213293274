import { createStore } from "vuex";
export default createStore({
  state: {
    target: null,
    // localStorage.getItem("target") ? localStorage.getItem("target") :
  },
  mutations: {
    setTarget(state, payload) {
      state.target = payload;
      // localStorage.setItem("target", payload);
    },
    clearTarget() {
      // localStorage.removeItem("target");
    },
  },
  actions: {
    SETTARGET(context, payload) {
      context.commit("setTarget", payload);
    },
    CLEARTARGET(context) {
      context.commit("clearTarget");
    },
  },
  modules: {},
});